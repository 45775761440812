.AppInput {
    input {
        width: 100%;
    }
}

.min-w-fullscreen {
    min-width: 1024px;
}

@media all and (min-width:768px) and (max-width: 959px) {
    .md\:min-w-desktop {
        min-width: 720px;
    }
}

@media all and (min-width:0px) and (max-width: 767px) {
    .sm\:min-w-mobile {
        min-width: 300px;
    }
}