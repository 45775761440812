.super_calendar.rbc-calendar {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.super_calendar {
  .rbc-header {
    background-color: #3498db;
    color: white;
  }

  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 0;
    text-align: left;
    width: 33.33%;
  }

  .rbc-toolbar-label {
    color: #fba106;
    font-family: Circular Std;
    font-size: 22px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    // text-align: left !important;
    padding: 20px 0px !important;
    font-weight: 500;
  }

  .rbc-btn-group:first-child {
    display: none
  }

  .rbc-btn-group button:nth-child(2) {
    display: none;
  }
  
  .rbc-btn-group:last-child {
    position: static;
    top: 0px;

    button {
      width: 100px;
      height: 52px;
      flex-shrink: 0;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid grey;
    }
  }

  .rbc-btn-group button:hover {
    background-color: #111c3a !important;
    color: #fff !important;
  }

  .add-event-btn {
    position: absolute;
    top: 45px;
    right: 40px;
  }

  .rbc-active {
    background-color: #111c3a !important;
    color: #fff !important;
  }

  .rbc-button-link {
    font-weight: bolder;
  }



  .rbc-toolbar {
    margin: 0;
    padding: 10px 20px;
    justify-content: flex-start;
  }

  .rbc-header {
    background-color: #fba106;
  }

  .rbc-row-segment {
    padding: 0px 6px 5px 6px !important;
  }

  .rbc-event {
    background-color: #FDD79B;
    color: #E38600;
  }

  .rbc-show-more {
    color: grey;
  }

  .month-select {
    width: 130px;
    height: 48px;

    .react-dropdown-select-dropdown-handle {
      margin-top: 5px !important;
    }

    border-radius: 4px;
    top: 70px;
    float: right;
    margin-right: 30px;
  }

  .date-select {
    width: 160px;
    height: 48px;
    border-radius: 4px;
    top: 70px;
    float: right;
    margin-right: 30px;
  }

  .btn-group {
    height: 40px;
    border-radius: 4px;
    top: 70px;
    float: left;
    margin-left: 30px;
    color: #fba106;
    font-family: Circular Std;

    .btn-outline-primary {
      color: #fba106;
      border-color: #fba106;
      font-weight: 500;
    }

    .btn-outline-primary:hover {
      color: white;
      background-color: #fba106;
      font-weight: 500;
    }
  }
}

.calendar-filter {
  label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #111c3a !important;
    border-color: #111c3a !important;
  }

  .ant-radio-button-wrapper {
    height: 45px;
    line-height: 40px;
  }
}
.rbc-calendar {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.rbc-header {
  background-color: #3498db;
  color: white;
}

.rbc-toolbar-label {
  color: #fba106;
  font-family: Circular Std;
  font-size: 22px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  // text-align: left !important;
  padding: 20px 0px !important;
  font-weight: 500;
}

.rbc-btn-group:first-child {
  display: none
}

.rbc-btn-group button:last-child {
  display: none;
}

.rbc-btn-group:last-child {
  position: absolute;
  top: 40px;

  button {
    width: 100px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid grey;
  }
}

.rbc-btn-group button:hover {
  background-color: #111c3a !important;
  color: #fff !important;
}

.add-event-btn {
  position: absolute;
  top: 45px;
  right: 40px;
}

.rbc-active {
  background-color: #111c3a !important;
  color: #fff !important;
}

.rbc-button-link {
  font-weight: bolder;
}



.rbc-toolbar {
  margin: 0;
  padding: 10px 20px;

}

.rbc-header {
  background-color: #fba106;
}

.rbc-row-segment {
  padding: 0px 6px 5px 6px !important;
}

.rbc-event {
  background-color: #FDD79B;
  color: #E38600;
}

.rbc-show-more {
  color: grey;
}

.month-select {
  width: 150px;
  height: 48px;

  .react-dropdown-select-dropdown-handle {
    margin-top: 5px !important;
  }

  border-radius: 4px;
  top: 70px;
  float: right;
  margin-right: 30px;
}

.date-select {
  width: 160px;
  height: 48px;
  border-radius: 4px;
  top: 70px;
  float: right;
  margin-right: 30px;
}

.btn-group {
  height: 40px;
  border-radius: 4px;
  top: 70px;
  float: left;
  margin-left: 30px;
  color: #fba106;
  font-family: Circular Std;

  .btn-outline-primary {
    color: #fba106;
    border-color: #fba106;
    font-weight: 500;
  }

  .btn-outline-primary:hover {
    color: white;
    background-color: #fba106;
    font-weight: 500;
  }
}

@media screen and (min-width: 768px) {
  .clear-all.schedule {
    position: absolute;
    left: -100px;
  }
}

.task_count_card {
  box-shadow: none !important;
  border: none;

  .bottom_box {
    background: #FDF3E3;
    color: #3D3C3A;
    border-radius: 0 0 0.5rem 0.5rem;
    border-color: transparent !important;

    .border-right {
      border-color: #8D8D8D5E !important;
    }
  }
}
.event-title {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  text-decoration: underline;
}

.e-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-trash-o {
  margin-left: 5px;
}
.pr-5{
  padding-right: 2%;
}

.task-status {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

.status-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.status-item:last-child {
  margin-right: 0;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid;
}

.scheduled {
  border-color: #B5B5B5;
  background-color: #E2E3E3;
}

.completed {
  border-color: #64C391;
  background-color: #ECFDF5;
}

.cancelled {
  border-color: #F53D15;
  background-color: #F4D3CC;
}

.status-text {
  font-size: 14px;
  color: #333;
}
