.AppLayout {
  background-color: #f5f5f5;
  overflow: hidden;
}

.Content {
  margin-top: 60px;
  margin-bottom: 40px;
  overflow: auto;
}
