// https://codepen.io/nelledejones/pen/gOOPWrK
.bounce {
    animation: bounce 0.5s infinite;
}

@keyframes bounce {
    from, to { transform: scale(1, 1); }
    25% { transform: scale(0.9, 1.1); }
    50% { transform: scale(1.1, 0.9); }
    75% { transform: scale(0.95, 1.05); }
}

.spin {
    animation: spin 1s infinite linear;
}
@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
  
.elastic-spin {
    animation: elastic-spin 1s infinite ease;
}
@keyframes elastic-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(720deg); }
}


.pulse {
    animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
    from { transform: scale(0.8); }
    to { transform: scale(1.2); }
}
  
.flash {
    animation: flash 500ms ease infinite alternate;
}
@keyframes flash {
    from { opacity: 1; }	
    to { opacity: 0; }
}
  
.hithere {
    animation: hithere 1s ease infinite;
}
@keyframes hithere {
    30% { transform: scale(1.2); }
    40%, 60% { transform: rotate(-20deg) scale(1.2); }
    50% { transform: rotate(20deg) scale(1.2); }
    70% { transform: rotate(0deg) scale(1.2); }
    100% { transform: scale(1); }
}