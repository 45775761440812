.media_box {
  min-height: 400px;
  padding: 24px 10px;
  box-shadow: none;
  border: none;

  .card-header,
  .card-body {
    border-bottom: none;
  }

  .card-footer {
    border-top: none
  }

  .card-header {
    .title {
      margin: 0;
      color: var(--primary);
      font-family: "CircularStd";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .card-body {
    .iframe_container {
      width: 100%;
      height: 100%;
      position: relative;
      min-height: 450px;
    }

    .content_description {
      color: #171717;
      font-family: "CircularStd";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }

    .mini_box_container {
      .mini_box {
        .title {
          color: #909090;
          font-family: "CircularStd";
          font-size: 16px;
          font-style: normal;
          font-weight: 450;
          margin-bottom: 0px !important;

        }

        .thumbnail_box {
          width: 100%;
          max-width: 60px;
          height: 50px;
          background-color: transparent;
          padding: 5px;

          img {
            width: 40px;
          }
        }

        label {
          cursor: pointer;
        }
      }
    }
  }

  .card-footer {
    .min-wd-100 {
      min-width: 100px;
    }

    .social_btn {
      border: 0;
      background-color: transparent;
      margin: 0;
      margin-right: 4px;
      margin-left: 4px;
      color: #363636;
      font-family: "CircularStd";
      font-size: 16px;
      font-style: normal;

      i {
        margin: 0;
        margin-right: 8px;
      }
    }
  }
}

.form_outer {
  margin: 1rem;
  padding: 16px;
  border-radius: 8px;
  background: #FFF;
}

.expand-btn {
  width: 30px;
  height: 30px;
  float: right;
  top: 40px;
  position: relative;
  background: white;
  right: 10px;
  z-index: 999;
  cursor: pointer;
}

#pdf-zoom-out {
  display: none !important;
}

#pdf-zoom-in {
  display: none !important;
}

#pdf-toggle-pagination {
  display: none !important;
}

#pdf-zoom-reset {
  display: none !important;
}

#pdf-controls {
  justify-content: center;
}

.custom_form {
  .option_box {
    padding: 10px 20px;
    margin: 0;
    margin-bottom: 8px;
    border-radius: 6px;
    border: 1px solid #D6D6D6;
    background: #FBFBFB;

    // .form-group {
    //     border-radius: 6px;
    //     border: 1px solid #D6D6D6;
    //     background: #FBFBFB;
    // }
    .col-form-label {
      font-size: 16px;
    }

    textarea.form-control {
      min-height: 100px;
      margin-bottom: 1rem;
    }

    .react-dropdown-select {
      margin-bottom: 1rem;
    }

    .outer {
      display: flex;
      flex-wrap: wrap;


      .list-group-item {
        border: none !important;
        background-color: transparent !important;

        label {
          display: flex;
          align-items: center;
          font-size: 16px;
        }

      }
    }
  }
}

a#pdf-download {
  display: none;
}

.block-hide {
  width: 160px;
  height: 40px;
  background-color: #000000;
  top: calc(100vh - 44%);
  left: calc(50vw - 22%);
  z-index: 99;
  position: absolute;
  display: none;

}

div#movie_player {
  background-color: #FFF;
}

.enable-scroll {
  overflow: auto;
  max-height: 456px;
}

.cui-toolbar-buttondock {
  display: none !important;
}

.alignright {
  display: none !important;
}

.doc-overlay {
  position: absolute;
  bottom: -28px;
  right: 0px;
  height: 40px;
  width: 90px;
  background-color: transparent;
  cursor: not-allowed;

}

.doc-overlay-full {
  position: fixed;
  top: auto;
  bottom: 0px;
  left: auto;
  right: 4px;
  height: 30px;
  width: 100px;
  background-color: transparent;
  cursor: not-allowed;
}

.assessment_history_box {
  border-radius: 12px;
  border: 1px solid #DADADA;
  max-width: 300px;
  background: #FBFBFB;

  .box_body{
    padding: 1rem;
  }
  h3 {
    font-family: "Lexend", sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #1F3227;
  }
  .bottom_box{
    background: #FBA106;
    text-align: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    a{
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.preview_assessment_box{
    list-style-type: none;
      padding: 0;
    
      .card {
        padding: 1rem;
        border-radius: 12px;
    
        &:hover {
          background-color: #fff !important;
        }
    
        &.correct {
          border: 1px solid #33BF74;
          
          .card-header{
            .question_box {
              i{
                color: #33BF74;
              }
            }
          }
        }
    
        &.wrong {
          border: 1px solid #BF3333;
          
          .card-header{
            .question_box {
              i{
                color: #BF3333;
              }
            }
          }
        }
    
        .card-header {
          display: flex;
          align-items: flex-start;
    
          .question_box {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;

            i{
              font-size: 20px;
            }
          }
        }

        .answer_box{
          padding: 1rem;
          display: flex;
          flex-wrap: wrap;

          .user_answer{
            border-right: 1px solid #726A6A8C;
          }
        }
      }
}