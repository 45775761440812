.courses {
    .courses_card {
        border-radius: 12px;
        background-color: #FFF;
        padding: 24px;
        cursor: pointer;

        &:hover {
            background-color: var(--primary);

            .title,
            p {
                color: white;
            }

            .btn {
                background-color: white;
                color: var(--primary);
            }
        }

        .btn {
            min-width: auto;
            padding: 8px 16px;
        }
    }
}