// CONTENT CARD
.content_card {
	padding: 20px 12px;
	cursor: pointer;
	height: 100%;

	&:hover {
		background-color: var(--primary_blue);

		.card-header,
		.card-footer {
			background-color: var(--primary_blue);
		}

		.main_title,
		.content {
			color: white;
		}

		.card-footer {
			button {
				background-color: white !important;
				color: var(--primary_blue);
			}
		}
	}

	.card-header,
	.card-body,
	.card-footer {
		border: none !important;
	}

	.main_title {
		color: var(--primary_blue);
		text-align: center;
		font-family: "Lexend";
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
	}

	.card-header {
		.circle_box {
			width: 70px;
			height: 70px;
			background-color: var(--primary_orange);
			border-radius: 300px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: white;

			.title {
				color: #fff;
				text-align: center;
				font-family: "Lexend";
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
			}

			.sub_title {
				color: #fff;
				font-family: "Lexend";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
			}

			p {
				margin: 0;
				margin-bottom: 4px;
			}
		}
	}

	.card-footer {
		button {
			color: #fff;
			font-family: "CircularStd";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			border: none;
		}
	}
}

// PILLAR CARD
.pillar_card {
	width: 100%;
	height: 400px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 20px;
	cursor: pointer;

	.card-body {
		width: 100%;
		padding: 16px 22px;
		position: absolute;
		bottom: 0;
		background-color: #303030a6;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;

		h3,
		p {
			color: white;
			margin: 0;
			margin-bottom: 4px;
		}

		hr {
			color: rgba(255, 255, 255, 0.23);
			opacity: 1;
		}

		.progress_circle_container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				color: rgba(255, 255, 255, 0.7);
				font-family: CircularStd;
				font-size: 14px;
			}
		}
	}
}
