.otp_container {
    justify-content: center;
    padding: 20px;

    input {
        margin: 4px 8px;
        width: 40px !important;
        height: 50px !important;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        background: #f5f5f5;
        color: black;
        font-size: clamp(16px, 2vw, 20px);
    }

}

.otp_top_text {
    color: #3F3F3F;
    text-align: center;
    font-family: "CircularStd";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.otp_timer {
    color: #3F3F3F;
    text-align: center;
    font-family: "CircularStd";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}